import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { Ticket, UserInfo, UserMe } from '@core/models';
import { SecurityService } from '@features/security';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable()
export class UserService {
  private userDataSubject = new BehaviorSubject<UserInfo | undefined>(undefined);
  private selectedOrganizationNameSubject = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
    private readonly securityService: SecurityService,
  ) {}

  public setUserData(userData: UserInfo) {
    this.userDataSubject.next(userData);
  }

  public getUserData(): Observable<UserInfo | undefined> {
    return this.userDataSubject.asObservable();
  }

  public setSelectedOrganizationName(orgName: string) {
    this.selectedOrganizationNameSubject.next(orgName);
  }

  public getSelectedOrganizationName(): Observable<string | undefined> {
    return this.selectedOrganizationNameSubject.asObservable();
  }

  public get user(): UserInfo | undefined {
    return this.userDataSubject.value;
  }

  public get userName(): string | undefined {
    return this.user?.name;
  }

  public get userEmail(): string | undefined {
    return this.user?.email;
  }

  public get userOrganization(): string | undefined {
    return this.user?.organization_name;
  }

  public get selectedOrganizationName(): string | undefined {
    return this.selectedOrganizationNameSubject.value;
  }

  public get userTimeZone(): string {
    return this.user?.timezone || '-0000';
  }

  public get isUserZendeskAgent(): boolean | undefined {
    return this.user?.zendesk_agent;
  }

  public get userTimeZoneShiftMinutes(): number {
    const hours = this.userTimeZone.substring(0, 3),
      minutes = this.userTimeZone.substring(3);
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }

  public loadUserData(): Observable<UserMe> {
    return this.http.get<UserMe>(`${this.apyBaseUrl}/api/users/me/`).pipe(
      tap((data) => this.securityService.decodePermissionsList(data.permissions)),
      tap((data) => this.setUserData(data)),
    );
  }

  public reloadUser(): void {
    this.loadUserData()
      .pipe(tap((data) => this.setUserData(data)))
      .subscribe();
  }

  public getTickets(id: number | null): Observable<Ticket[]> {
    return this.http.get<Ticket[]>(`${this.apyBaseUrl}/api/user-tickets/` + (id ? `?as_customer=${id}` : ''));
  }
}
