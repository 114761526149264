import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { WidgetAccessService } from '@core/services/widget-access/widget-access.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MenuComponent {
  relatedRoute = input<string[]>([]);
  canAccessHomePage = computed<boolean>(() => {
    return this.widgetAccessService.hasAccessibleWidgets();
  });
  constructor(private readonly widgetAccessService: WidgetAccessService) {}

  buildRoute(path: string[]) {
    return ['/', ...this.relatedRoute(), ...path];
  }
}
