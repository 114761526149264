import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { AccountService } from '../services/account/account.service';

export const noRoleAccountGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const account = inject(AccountService);

  return of(!!account.accessToken).pipe(
    switchMap((state: boolean) => {
      if (!state) {
        return of(router.createUrlTree(['/auth/login']));
      }
      return of(state);
    }),
  );
};
