<div class="statusInfo px-2 d-flex flex-direction-column g-2">
  <div>
    <mat-chip-set
      aria-label="Status Pending Approval"
      class="status-chip chip-size-m"
      [ngClass]="'Pending Approval' | statusColor"
    >
      <mat-chip>Pending Approval</mat-chip>
    </mat-chip-set>
    <div>All the data needed for approval is in place.</div>
  </div>
  <div>
    <mat-chip-set
      aria-label="Status Approved"
      class="status-chip chip-size-m"
      [ngClass]="'Approvedconnector' | statusColor"
    >
      <mat-chip>Approved</mat-chip>
    </mat-chip-set>
    <div>Connector has been approved - Automation needs to pick this and setup automated downloads</div>
  </div>
  <div>
    <mat-chip-set aria-label="Status Automated" class="status-chip chip-size-m" [ngClass]="'Automated' | statusColor">
      <mat-chip>Automated</mat-chip>
    </mat-chip-set>
    <div>Automation process for the connector has started</div>
  </div>
  <div>
    <mat-chip-set
      aria-label="Status Needs Schema Approval"
      class="status-chip chip-size-m"
      [ngClass]="'Needs Schema Approval' | statusColor"
    >
      <mat-chip>Needs Schema Approval</mat-chip>
    </mat-chip-set>
    <div>Automation found change to Schema since last run</div>
  </div>
  <div>
    <mat-chip-set
      aria-label="Status Pending Schema Approval"
      class="status-chip chip-size-m"
      [ngClass]="'Pending Schema Approval' | statusColor"
    >
      <mat-chip>Pending Schema Approval</mat-chip>
    </mat-chip-set>
    <div>
      Scheduled run has been halted, a new Discovery Ingest has been performed and customer has been notified - awaiting
      customer approval
    </div>
  </div>
  <div>
    <mat-chip-set aria-label="Status Success" class="status-chip chip-size-m" [ngClass]="'Success' | statusColor">
      <mat-chip>Success</mat-chip>
    </mat-chip-set>
    <div>Automation process for the connector has started. Last run was successful</div>
  </div>
  <div>
    <mat-chip-set aria-label="Status Failure" class="status-chip chip-size-m" [ngClass]="'Failure' | statusColor">
      <mat-chip>Failure</mat-chip>
    </mat-chip-set>
    <div>Automation process for the connector has started. Last run was unsuccessful</div>
  </div>
</div>
