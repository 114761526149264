<div class="px-2">
  <div class="pb-2">
    <mat-chip-set
      aria-label="Status Pending Technical Details"
      class="status-chip chip-size-m"
      [ngClass]="'Pending technical details' | statusColor"
    >
      <mat-chip>Pending Technical Details</mat-chip>
    </mat-chip-set>
    <div>
      Data Source was added
      <br />
      Tech details are needed to complete its configuration
    </div>
  </div>
  <div class="pb-2">
    <mat-chip-set
      aria-label="Status Pending Connection"
      class="status-chip chip-size-m"
      [ngClass]="'Pending Connection' | statusColor"
    >
      <mat-chip>Pending Connection</mat-chip>
    </mat-chip-set>
    <div>Waiting for Automation to make connections and start discovery process</div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Discovery " class="status-chip chip-size-m" [ngClass]="'Discovery' | statusColor">
      <mat-chip>Discovery</mat-chip>
    </mat-chip-set>
    <div>Automation is working to discover details of this data source</div>
  </div>
  <div class="pb-2">
    <mat-chip-set
      aria-label="Status Connection Failed"
      class="status-chip chip-size-m"
      [ngClass]="'Connection failed' | statusColor"
    >
      <mat-chip>Connection Failed</mat-chip>
    </mat-chip-set>
    <div>Backend Automation tried to connect, but failed - tech owner needs to update details</div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Connected" class="status-chip chip-size-m" [ngClass]="'Connected' | statusColor">
      <mat-chip>Connected</mat-chip>
    </mat-chip-set>
    <div>Data Source is now ready for review, approve and to be added as connector</div>
  </div>
</div>
