import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Observable, tap } from 'rxjs';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root',
})
export class AccessPageService {
  constructor(
    @Inject('defaultPath') protected readonly path: string,
    private readonly security: SecurityService,
    private readonly router: Router,
  ) {}

  canActivate(permissions: string[]): Observable<boolean> {
    return this.security.getPermissionsList().pipe(
      filter((permissions) => permissions.length > 0),
      map(() => this.security.hasAccess(permissions)),
      tap((access) => {
        if (!access) {
          this.router.navigateByUrl(this.path, { skipLocationChange: true });
        }
      }),
    );
  }
}
