import { Injectable } from '@angular/core';
import { SecurityService } from '@features/security';

export interface PagePermission {
  page: string | null;
  permission: string;
}

@Injectable()
export class WidgetAccessService {
  private readonly homePageWidgets: PagePermission[] = [
    { page: 'data-sources', permission: 'view_datasource' },
    { page: 'connectors', permission: 'view_connector' },
    { page: 'pipelines', permission: 'view_pipeline' },
    { page: null, permission: 'view_zendeskticket' },
  ];

  private readonly noWidgetMenuItems: PagePermission[] = [
    { page: 'vpn-configuration', permission: 'view_vpnconfig' },
    { page: 'user-management', permission: 'view_user' },
    { page: 'view_auditlog', permission: 'audit-log' },
  ];

  constructor(private securityService: SecurityService) {}

  private getAccessibleWidgets(): PagePermission[] {
    return this.homePageWidgets.filter((widget) => this.securityService.hasAccess([widget.permission]));
  }

  private getAccessibleMenuItems(): PagePermission[] {
    return this.noWidgetMenuItems.filter((widget) => this.securityService.hasAccess([widget.permission]));
  }

  hasAccessibleWidgets(): boolean {
    const accsessibleWidgets = this.getAccessibleWidgets();
    return accsessibleWidgets.length > 1 || accsessibleWidgets.some((page) => page.permission === 'view_zendeskticket');
  }

  getFirstAccessiblePage(): string | null {
    const widgets = this.getAccessibleWidgets();
    const menuItems = this.getAccessibleMenuItems();

    return widgets.length > 0 ? widgets[0].page : menuItems.length > 0 ? menuItems[0].page : null;
  }
}
