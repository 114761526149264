<div class="col-auto side-space d-none d-xl-block"></div>
<div class="col-full seatch-panel">
  <mat-form-field class="input-normalize" *accessView="['view_search']">
    <input type="search" matInput placeholder="Search" disabled="disabled" />
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
</div>
<div class="col-auto d-flex side-space">
  <button mat-icon-button disabled="disabled" class="tools-button" *accessView="['view_notifications']">
    <mat-icon>notifications_none</mat-icon>
  </button>
  <button mat-icon-button class="tools-button" [matTooltip]="'Dark/Light mode'" (click)="onToggle()">
    @if (isDarkTheme()) {
      <mat-icon>sunny</mat-icon>
    } @else {
      <mat-icon>dark_mode</mat-icon>
    }
  </button>
  @if (userInfo().snowflake_url) {
    <button
      mat-icon-button
      class="tools-button"
      (click)="navigateToSnowflake()"
      [matTooltip]="'Snowflake'"
      data-testId="snowflake_button"
    >
      <mat-icon>ac_unit</mat-icon>
    </button>
  }
  @if (userInfo().thoughspot_url) {
    <button
      mat-icon-button
      class="tools-button"
      (click)="navigateToThoughspot()"
      [matTooltip]="'ThoughtSpot'"
      data-testId="thoughspot_button"
    >
      <mat-icon>no_flash</mat-icon>
    </button>
  }
  <button
    mat-icon-button
    class="tools-button"
    (click)="navigateToDilikoStatusPage()"
    [matTooltip]="'Diliko Status Page'"
    data-testId="diliko_status_page_button"
  >
    <!-- todo: refactor once icons font is updated -->
    <mat-icon svgIcon="status_page" class="svg-icon"></mat-icon>
  </button>
</div>
