.error-logo {
  width: 100%;
  max-width: 90vw;
}
@media (min-width: 992px) {
  .error-content {
    height: calc(100vh - var(--mat-toolbar-standard-height));
  }
}

.error-text-content {
  text-align: center;
  .error-code {
    font-size: calc(var(--rp-page-font-size) * 3.5625);
    line-height: calc(var(--rp-page-font-size) * 4);
    color: var(--rp-primary-color);
  }

  .error-main-text {
    font-size: calc(var(--rp-page-font-size) * 2.25);
    line-height: calc(var(--rp-page-font-size) * 2.75);
  }

  .error-nav-button {
    width: 180px;
  }
}
