import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { OrganizationDetailsDTO, OrganizationItemDTO } from '@core/models';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable()
export class OrganizationsService {
  private selectedOrganizationSubject = new BehaviorSubject<OrganizationDetailsDTO | undefined>(undefined);

  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apyBaseUrl: string,
  ) {}

  public setSelectedOrganizationData(organizationData: OrganizationDetailsDTO) {
    this.selectedOrganizationSubject.next(organizationData);
  }

  public getSelectedOrganizationData(): Observable<OrganizationDetailsDTO | undefined> {
    return this.selectedOrganizationSubject.asObservable();
  }

  public get selectedOrganization(): OrganizationDetailsDTO | undefined {
    return this.selectedOrganizationSubject.value;
  }

  public get selectedOrganizationName(): string {
    return this.selectedOrganization?.organization.name || '<no organization>';
  }

  public get selectedOrganizationShortName(): string {
    return this.selectedOrganization?.organization.short_name || '<no organization>';
  }

  public get selectedOrganizationSnowflakeUrl(): string | null {
    const snowflake = this.selectedOrganization?.organization?.product_links.filter(
      (product) => product.link_type === 'Snowflake',
    );
    return snowflake?.length ? snowflake[0].url : null;
  }

  public get selectedOrganizationThoughtSpotUrl(): string | null {
    const thoughtSpot = this.selectedOrganization?.organization?.product_links.filter(
      (product) => product.link_type === 'ThoughtSpot',
    );
    return thoughtSpot?.length ? thoughtSpot[0].url : null;
  }

  public get selectedOrganizationTimeZone(): string {
    return this.selectedOrganization?.organization?.timezone || '-0000';
  }

  public get selectedOrganizationTimeZoneShiftMinutes(): number {
    const hours = this.selectedOrganizationTimeZone.substring(0, 3),
      minutes = this.selectedOrganizationTimeZone.substring(3);
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  }

  public getList(): Observable<OrganizationItemDTO[]> {
    return this.http.get<OrganizationItemDTO[]>(`${this.apyBaseUrl}/api/organizations-internal/`);
  }

  public getData(id: string | null): Observable<OrganizationDetailsDTO> {
    return this.http
      .get<OrganizationDetailsDTO>(`${this.apyBaseUrl}/api/dashboard/` + (id ? `?as_customer=${id}` : ''))
      .pipe(tap((data) => this.setSelectedOrganizationData(data)));
  }
}
