import { Component, signal } from '@angular/core';
import { UserService } from '@core/services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-support-links-panel',
  standalone: false,
  templateUrl: './support-links-panel.component.html',
  styleUrl: './support-links-panel.component.scss',
})
export class SupportLinksPanelComponent {
  public isZendeskAgent = signal<boolean>(false);

  constructor(private userService: UserService) {
    this.isZendeskAgent.set(this.userService.isUserZendeskAgent ? this.userService.isUserZendeskAgent : false);
  }

  get zendeskLink() {
    return environment.zendeskUrl + (this.isZendeskAgent() ? '/agent/' : '/hc/en-us/requests/organization');
  }

  get mailToSupport() {
    return `mailto:${environment.feedbackEmail}`;
  }
}
