<div class="statusInfo px-2 d-flex flex-direction-column g-2">
  <div>
    <mat-chip-set
      aria-label="Status Pending Approval"
      class="status-chip chip-size-m"
      [ngClass]="'Pending Approval' | statusColor"
    >
      <mat-chip>Pending Approval</mat-chip>
    </mat-chip-set>
    <div>All the necessary data are ready and waiting for approval.</div>
  </div>
  <div>
    <mat-chip-set
      aria-label="Status Approved"
      class="status-chip chip-size-m"
      [ngClass]="'Approvedpipeline' | statusColor"
    >
      <mat-chip>Approved</mat-chip>
    </mat-chip-set>
    <div>All added data sources are promoted; All added connectors are ready.</div>
  </div>
  <div>
    <mat-chip-set
      aria-label="Status Needs Schema Approval"
      class="status-chip chip-size-m"
      [ngClass]="'Errors' | statusColor"
    >
      <mat-chip>Errors</mat-chip>
    </mat-chip-set>
    <div>Pipeline is implemented; Last run was unsuccessful.</div>
  </div>
  <div>
    <mat-chip-set aria-label="Status Automated" class="status-chip chip-size-m" [ngClass]="'Published' | statusColor">
      <mat-chip>Published</mat-chip>
    </mat-chip-set>
    <div>Pipeline is implemented; Last run was successful.</div>
  </div>
</div>
