<div class="px-2">
  <div class="pb-2">
    <mat-chip-set aria-label="Status New" class="status-chip chip-size-m" [ngClass]="'New' | statusColor">
      <mat-chip>New</mat-chip>
    </mat-chip-set>
    <div>
      Data source has been added, but still waiting to have discovery completed.
      <br />
      Please review connect status.
    </div>
  </div>
  <div class="pb-2">
    <mat-chip-set
      aria-label="Status Under Discovery"
      class="status-chip chip-size-m"
      [ngClass]="'Under Discovery' | statusColor"
    >
      <mat-chip>Under Discovery</mat-chip>
    </mat-chip-set>
    <div>
      Discovery was completed for the data source.
      <br />
      It needs to be reviewed and approved.
    </div>
  </div>
  <div class="pb-2">
    <mat-chip-set aria-label="Status Approved " class="status-chip chip-size-m" [ngClass]="'Approved' | statusColor">
      <mat-chip>Approved</mat-chip>
    </mat-chip-set>
    <div>The discovery for the data source was completed and the data source has been approved.</div>
  </div>
</div>
