import { AfterViewInit, DestroyRef, Directive, Host, Inject, input, Optional, Renderer2, Self } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTable } from '@angular/material/table';
import { SESSION_STORAGE } from '@core/injectors';
import { debounceTime, delay, fromEvent, map } from 'rxjs';

@Directive({
  selector: '[tableScrollRemember]',
  standalone: false,
})
export class TableScrollRememberDirective implements AfterViewInit {
  private readonly DELAY_SET_SCROLL = 100;

  tableName = input.required<string>({ alias: 'tableScrollRemember' });

  constructor(
    @Host() @Self() @Optional() private readonly table: MatTable<any>,
    @Inject(SESSION_STORAGE) private readonly sessionStorage: Storage,
    private readonly destroyRef: DestroyRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (!this.table.fixedLayout) {
      this.manageScrollPosition();
    }
  }

  /**
   * Manage the scroll position of the table.
   * When the table content changed, get the scroll position from the session storage
   * and set it to the table.
   * When the table is scrolled, get the current scroll position and store it in the session storage.
   */
  public manageScrollPosition(): void {
    const parent: HTMLDivElement = this.renderer.parentNode((this.table as any)._elementRef.nativeElement);

    /**
     * When the table content changed, get the scroll position from the session storage
     * and set it to the table.
     */
    this.table.contentChanged.pipe(delay(this.DELAY_SET_SCROLL), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      const position = this.sessionStorage.getItem(this.tableName()) || '0:0';
      const [left, top] = position.split(':');
      parent.scrollLeft = parseInt(left, 10);
      parent.scrollTop = parseInt(top, 10);
    });

    /**
     * When the table is scrolled, get the current scroll position and store it in the session storage.
     */
    fromEvent(parent, 'scroll')
      .pipe(
        debounceTime(100),
        map((ev: any): string => `${ev.target.scrollLeft}:${ev.target.scrollTop}`),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((scrollPosition: string) => {
        this.sessionStorage.setItem(this.tableName(), scrollPosition);
      });
  }
}
