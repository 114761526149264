import { MatTableDataSource } from '@angular/material/table';

export class CustomSortingTableDataSource<T> extends MatTableDataSource<T> {
  constructor(data: T[] = []) {
    super(data);

    this.sortingDataAccessor = (item: T, property: string): string | number => {
      const value = item[property as keyof T];

      switch (true) {
        case property === 'categories' && Array.isArray(value):
          return typeof value[0]?.name === 'string' ? value[0].name.toLowerCase() : '';
        case typeof value === 'boolean':
          return value ? 1 : 0;
        case typeof value === 'string':
          return value.toLowerCase();
        case typeof value === 'number':
          return value;
        case value instanceof Date:
          return value.getTime();
        case value === null || value === undefined:
          return '';
        default:
          return '';
      }
    };
  }
}
