import { ConnectorsStatusesType } from './connectors-statuses.type';
import { DataSourcesStatusesType } from './data-source/data-source-statuses.type';
import { PipelinesStatusesType } from './pipelines-statuses.type';

export const ERROR_TOOLTIP_STATUSES = {
  Connectors: ['pending schema approval', 'failure'] as ConnectorsStatusesType[],
  DataSources: ['connection failed'] as DataSourcesStatusesType[],
  Pipelines: ['errors'] as PipelinesStatusesType[],
};

export const CONNECTOR_APPROVAL_STATUSES = ['pending schema approval', 'pending approval'] as ConnectorsStatusesType[];
