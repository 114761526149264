export enum DataSourceDataType {
  Text = 'Text',
  Integer = 'Integer',
  Decimal = 'Decimal',
  DateTime = 'DateTime',
  JSON = 'JSON',
  Boolean = 'Boolean',
}

export const DataSourceDataTypes = [
  DataSourceDataType.Text,
  DataSourceDataType.Integer,
  DataSourceDataType.Decimal,
  DataSourceDataType.DateTime,
  DataSourceDataType.JSON,
  DataSourceDataType.Boolean,
];

export const DataSourceDataTypeMap = new Map<string, DataSourceDataType>([
  ['text', DataSourceDataType.Text],
  ['integer', DataSourceDataType.Integer],
  ['decimal', DataSourceDataType.Decimal],
  ['datetime', DataSourceDataType.DateTime],
  ['json', DataSourceDataType.JSON],
  ['boolean', DataSourceDataType.Boolean],
]);

export const validDataTypes = [DataSourceDataType.Integer, DataSourceDataType.DateTime];
