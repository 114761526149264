import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  protected allowEmptyUserPermission = true;

  public userPermissions$ = new BehaviorSubject<string[]>([]);

  getPermissionsList(): Observable<string[]> {
    return this.userPermissions$.asObservable();
  }

  hasNoPermissions(): boolean {
    const permissions = this.userPermissions$.value;
    return permissions.length < 1 || permissions.includes('no_permission');
  }

  decodePermissionsList(permissions: string[]): boolean {
    this.userPermissions$.next(permissions);
    return true;
  }

  hasAccess(permissions: string[]): boolean {
    return permissions?.some((permission) => this.userPermissions$.value?.includes(permission));
  }

  hasAccessAsync(permissions: string[]): Observable<boolean> {
    return this.userPermissions$.pipe(
      map((userPermissions) => {
        return permissions?.some((permission) => userPermissions.includes(permission));
      }),
    );
  }
}
