import { Component, input, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OrganizationDetailsDTO, UserInfo } from '@core/models';
import { OrganizationsService, UserService } from '@core/services';
import { AccountService } from '@features/server-side-authentication';
import * as Sentry from '@sentry/angular';
import { map, Observable, of, Subscription, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: false,
})
export class HeaderComponent {
  public homeUrl = input<string[]>(['/']);

  public userInfo = toSignal<UserInfo>(
    this.userService.getUserData().pipe(
      map((el) => {
        return {
          name: el?.name || '<user name>',
          snowflake_url: el?.snowflake_url || '',
          zendesk_agent: el?.zendesk_agent || false,
          thoughspot_url: el?.thoughspot_url || '',
        };
      }),
    ),
  );

  public userOrganization = signal<string | Observable<OrganizationDetailsDTO> | Observable<string>>('');
  private organizationDataSubsctiption!: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private accountService: AccountService,
    private organizationsService: OrganizationsService,
  ) {}

  ngOnInit() {
    if (!this.accountService.isInternal && this.organizationsService.selectedOrganization) {
      this.setOrganizationData();
    } else {
      const organizationObservable: Observable<string | null> = this.accountService.isInternal
        ? this.route.params.pipe(map<Params, string>((params) => params['organizationId']))
        : of(null);
      this.organizationDataSubsctiption = organizationObservable
        .pipe(
          switchMap((orgId) => this.organizationsService.getData(orgId)),
          tap(() => this.setOrganizationData()),
        )
        .subscribe();
    }
  }

  private setOrganizationData() {
    Sentry.setTag('customer_short_name', this.organizationsService.selectedOrganizationShortName);
    this.userService.setSelectedOrganizationName(this.organizationsService.selectedOrganizationName);
    this.userOrganization.set(this.organizationsService.selectedOrganizationName);
  }

  public logout(): void {
    this.router.navigate(['/', 'auth', 'logout']);
  }

  ngOnDestroy() {
    this.organizationDataSubsctiption?.unsubscribe();
  }
}
