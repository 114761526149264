export const StatusToColorClassMap = new Map<string, string>([
  ['new', 'primary'],
  ['pending connection', 'pending'],
  ['pending technical details', 'pending'],
  ['pending approval', 'pending'],
  ['under discovery', 'pending'],
  ['pending_approval', 'pending'],
  ['under_discover', 'pending'],
  ['connected', 'success'],
  ['success', 'success'],
  ['approved', 'success'],
  ['pending schema approval', 'error'],
  ['connection failed', 'error'],
  ['failure', 'error'],
  ['pending connection', 'discovery'],
  ['approvedconnector', 'discovery'],
  ['automated', 'discovery'],
  ['discovery', 'discovery'],
  ['deleted', 'discovery'],
  ['needs schema approval', 'discovery'],
  // ZenDesk issues
  ['new', 'primary'],
  ['open', 'primary'],
  ['pending', 'pending'],
  ['on-hold', 'pending'],
  ['closed', 'success'],
  ['solved', 'success'],
  // Customer Type
  ['universal', 'universal'],
  // Vpn cards
  ['in_progress', 'pending'],
  ['under_config', 'discovery'],
  ['connected', 'success'],
  ['submitted', 'discovery'],
  // Pipelines
  ['approvedpipeline', 'discovery'],
  ['published', 'success'],
  ['errors', 'error'],
]);
