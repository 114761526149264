import { Component, Inject, input, OnInit, signal } from '@angular/core';
import { WINDOW } from '@core/injectors';
import { UserInfo } from '@core/models';
import { ThemeService } from '@core/services';

@Component({
  selector: 'app-tools-panel',
  templateUrl: './tools-panel.component.html',
  styleUrl: './tools-panel.component.scss',
  standalone: false,
})
export class ToolsPanelComponent implements OnInit {
  userInfo = input.required<UserInfo>();
  isDarkTheme = signal<boolean>(false);

  constructor(
    private readonly themeService: ThemeService,
    @Inject(WINDOW) private readonly win: Window,
  ) {}

  ngOnInit(): void {
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }

  onToggle() {
    this.themeService.toggle();
    this.isDarkTheme.set(this.themeService.isDarkTheme);
  }

  navigateToSnowflake() {
    this.win.open(this.userInfo()?.snowflake_url);
  }

  navigateToThoughspot() {
    this.win.open(this.userInfo()?.thoughspot_url);
  }

  navigateToDilikoStatusPage() {
    this.win.open('https://diliko.statuspage.io/');
  }
}
