<div class="p-2 menu-navigation">
  <mat-nav-list>
    <a
      mat-list-item
      [href]="zendeskLink"
      target="_blank"
      *accessView="['view_zendeskticket']"
      data-testId="zendesk_button"
    >
      <div class="menu-item">
        <mat-icon class="icon">headset_mic</mat-icon>
        <div class="menu-item-text">Support</div>
      </div>
    </a>

    <a mat-list-item [href]="mailToSupport" data-testId="feedback_button">
      <div class="menu-item">
        <mat-icon class="icon" svgIcon="feedback"></mat-icon>
        <div class="menu-item-text">Feedback</div>
      </div>
    </a>
  </mat-nav-list>
</div>
