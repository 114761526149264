import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from '@core/injectors';
import { sortObjectArrayByKey } from '@core/utils';
import { CategoryDTO } from '@features/categories/models';
import { map, Observable } from 'rxjs';
import {
  DataSourceColumnItemDTO,
  DataSourceColumnItemUpdateDTO,
  DataSourceDataTypeMap,
  DataSourceItemDTO,
  DataSourceTableItemDTO,
  DataSourceTableItemUpdateDTO,
  DataSourceTablesForeignKeyDTO,
  DataSourceUpgradeableDTO,
  PaginatedTablesResponse,
} from '../../models';

@Injectable()
export class DataSourceDataService {
  constructor(
    private readonly http: HttpClient,
    @Inject(API_BASE_URL) private readonly apiBaseUrl: string,
  ) {}

  getList(): Observable<DataSourceItemDTO[]> {
    return this.http.get<DataSourceItemDTO[]>(`${this.apiBaseUrl}/api/data-sources/`).pipe(
      map((dataSources) => {
        return dataSources.map((dataSource) => {
          return {
            ...dataSource,
            categories: dataSource.categories ? sortObjectArrayByKey(dataSource.categories, 'name', true) : [],
          };
        });
      }),
    );
  }

  getResourceList(): Observable<DataSourceUpgradeableDTO[]> {
    return this.http.get<DataSourceUpgradeableDTO[]>(`${this.apiBaseUrl}/api/data-sources/upgradeable/`);
  }

  getResourceItem(id: number): Observable<DataSourceUpgradeableDTO> {
    return this.http.get<DataSourceUpgradeableDTO>(`${this.apiBaseUrl}/api/data-sources/${id}/`);
  }

  getDataSourcesItem(id: number): Observable<DataSourceItemDTO> {
    return this.http.get<DataSourceItemDTO>(`${this.apiBaseUrl}/api/data-sources/${id}/`).pipe(
      map((dataSource) => {
        return {
          ...dataSource,
          categories: dataSource.categories ? sortObjectArrayByKey(dataSource.categories, 'name', true) : [],
        };
      }),
    );
  }

  getDataSourcesTablesForeignKey(tableId: number): Observable<DataSourceTablesForeignKeyDTO[]> {
    return this.http.get<DataSourceTablesForeignKeyDTO[]>(
      `${this.apiBaseUrl}/api/data-source-tables/${tableId}/possible_foreign_keys`,
    );
  }

  getDataSourceTables(id: number, page: number): Observable<PaginatedTablesResponse> {
    return this.http
      .get<PaginatedTablesResponse>(`${this.apiBaseUrl}/api/data-sources/${id}/tables/`, { params: { page } })
      .pipe(
        map(({ count, next, previous, results }) => ({
          count,
          next,
          previous,
          results: results.map((table) => ({
            ...table,
            categories: table.categories ? sortObjectArrayByKey(table.categories, 'name', true) : [],
          })),
        })),
      );
  }

  getDataSourceTableItem(id: number): Observable<DataSourceTableItemDTO> {
    return this.http.get<DataSourceTableItemDTO>(`${this.apiBaseUrl}/api/data-source-tables/${id}/`).pipe(
      map((table) => {
        return {
          ...table,
          categories: table.categories ? sortObjectArrayByKey(table.categories, 'name', true) : [],
        };
      }),
    );
  }

  getDataSourceTableColumns(id: number): Observable<DataSourceColumnItemDTO[]> {
    return this.http.get<DataSourceColumnItemDTO[]>(`${this.apiBaseUrl}/api/data-source-tables/${id}/columns/`).pipe(
      map((columns) => {
        return columns.map((column) => {
          return {
            ...column,
            categories: column.categories ? sortObjectArrayByKey(column.categories, 'name', true) : [],
          };
        });
      }),
    );
  }

  getDataSourceTableColumnsConverted(id: number): Observable<DataSourceColumnItemDTO[]> {
    return this.getDataSourceTableColumns(id).pipe(
      map((dataSourceColumn) => {
        return dataSourceColumn.map((column) => ({
          ...column,
          // todo: stored_data_type change to uppercase by default. use pipe to convert to camelCase
          stored_data_type:
            DataSourceDataTypeMap.get(column.stored_data_type?.toLowerCase()) ?? column.stored_data_type,
          data_type: DataSourceDataTypeMap.get(column.data_type?.toLowerCase()) ?? column.data_type,
        }));
      }),
    );
  }

  updateDataSourceTableItem(id: number, data: DataSourceTableItemUpdateDTO): Observable<DataSourceTableItemDTO> {
    return this.http.patch<DataSourceTableItemDTO>(`${this.apiBaseUrl}/api/data-source-tables/${id}/`, data);
  }

  updateDataSourceColumnItem(id: number, data: DataSourceColumnItemUpdateDTO): Observable<DataSourceColumnItemDTO> {
    return this.http.patch<DataSourceColumnItemDTO>(`${this.apiBaseUrl}/api/data-source-columns/${id}/`, data);
  }

  getCategories(): Observable<CategoryDTO[]> {
    return this.http
      .get<CategoryDTO[]>(`${this.apiBaseUrl}/api/categories/`)
      .pipe(map((categories) => sortObjectArrayByKey(categories, 'name', true)));
  }
}
